<!--
 * @Author: 张阳帅
 * @Date: 2024-09-23 17:52:37
 * @LastEditTime: 2024-11-20 17:02:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\gjc\wxCode\index.vue
-->
<template>
  <div />
</template>
<script>
import { WX_CONST } from "../../../utils/constant"
import { userModel } from "@/api/user"
export default {
    mounted() {
        console.log(this.$store.getters.wxLogin, "wxLogin---wxCode")
        this.$store.getters.wxLogin !== 1 ? this.$router.back() : this.getCode()
        window.addEventListener('pageshow', function(e) {
            // 通过persisted属性判断是否存在 BF Cache
            console.log(e, "pageshow")
            if (e.persisted) {
                location.reload()
            }
        })
    },
    created(){
    },
    methods:{
        // 获取链接授权code,并通过code获取到openid
        getCode(url) {
            const reg = /code=(.*)?&/g
            const result = reg.exec(window.location.href)
            let code = ""
            if (result && result.length) {
                code = result[1]
            }
            if (!code) {
                this.getWxCode(url)
            } else {
                this.getToken(code)
            }
        },
        getWxCode(){
            const url = encodeURIComponent(window.location.href)
            window.location.href =
                "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
                WX_CONST.appid +
                "&redirect_uri=" +
                url +
                "&response_type=code&scope=snsapi_base#wechat_redirect"
        },
        getToken(code) {
            userModel.getWxTokenByCode({ wxmpCode: code }).then((res) => {
                if (res.code == 200) {
                  sessionStorage.setItem("wxToken", res.data.wxtoken)
                  setTimeout(() => {
                    this.$router.push({
                        path:'/login'
                    })
                  }, 100)
                }
            })
        }
    }
}
</script>
<style scoped lang='less'>
</style>
